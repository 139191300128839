import mixpanel from "mixpanel-browser";

export const EVENT = {
  NAV_CLICK: "Navigation-click",
  CALENDAR_EVENT_CREATE: "Calendar-Event-create",
  CALENDAR_EVENT_UPDATE: "Calendar-Event-update",
  CALENDAR_EVENT_ADD_CLICK: "New-Event-start",
  CALENDAR_EVENT_SAVE_CLICK: "New-Event-save",
  CALENDAR_EVENT_CANCEL_CLICK: "New-Event-cancel",
  ADD_CLIENT_START: "Add-Client-start",
  ADD_CLIENT_CONTINUE_CLICK: "Add-Client-Continue-click",
  ADD_CLIENT_SAVE: "Add-Client-save",
  APPOINTMENT_ACCEPT: "Appointment-accept",
  APPOINTMENT_DECLINE: "Appointment-decline",
  EVENT_DETAILS_VIEW: "Event-Details-view",
  SETTINGS_DETAILS_SAVE: "Settings-Details-save",
  SETTINGS_AVAILABILITY_COPY: "Settings-Availability-copy",
  SETTINGS_AVAILABILITY_SAVE: "Settings-Availability-save",
}

export const CALENDAR_EVENT_TYPE = {
  APPOINTMENT: "Appointment",
  UNAVAILABLE: "Unavailable",
}

export const PAGE = {
  CALENDAR: "Calendar",
  CLIENTS: "Clients",
  EVENT_DETAILS: "Event Details",
  NEW_EVENT: "New Event",
  NEW_CLIENT: "New Client",
  REQUESTS: "Requests",
  SETTINGS: "Settings",
}

export const VISIT_TYPE = {
  HOME: "Home",
  OFFICE: "Office",
  VIDEO: "Video",
  UNKNOWN: "Unknown",
}

export const initializeMixpanel = () => {
  const mixpanelToken = process.env.REACT_APP_MIXPANEL_TOKEN;
  const production = process.env.NODE_ENV === "production";
  const debug = !production

  if (mixpanelToken) {
    mixpanel.init(mixpanelToken, {
      debug,
      ignore_dnt: debug,
    });
    return mixpanel;
  } else {
    if (production) {
      throw new Error("Must provide valid MIXPANEL_TOKEN for Mixpanel SDK");
    } else {
      return null;
    }
  }
}
