import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { withAuthenticationRequired } from "@auth0/auth0-react";

import Loadable from "react-loadable";
import Loading from "components/Loading";

import Callback from "layouts/Callback";
import AppLogout from "components/AppLogout";
import { DashboardProvider } from "context/dashboardContext";
import { FiltersProvider } from "context/filtersContext";

const Home = Loadable({
  loader: () => import("layouts/Home"),
  loading: Loading,
});

const NotFound = Loadable({
  loader: () => import("layouts/NotFound"),
  loading: Loading,
});

const Logout = Loadable({
  loader: () => import("layouts/Logout"),
  loading: Loading,
});

const Dashboard = Loadable({
  loader: () => import("layouts/Dashboard"),
  loading: Loading,
});

const File = Loadable({
  loader: () => import("layouts/File"),
  loading: Loading,
});

const ConnectedCalendar = Loadable({
  loader: () => import("layouts/ConnectedCalendar"),
  loading: Loading,
});

const routes = [
  {
    path: "/",
    exact: true,
    component: Home,
    isPrivate: false,
  },
  {
    path: "/logout",
    exact: true,
    component: Logout,
    isPrivate: false,
  },
  {
    path: "/dashboard",
    exact: false,
    component: Dashboard,
    isPrivate: true,
  },
  {
    path: "/file",
    exact: false,
    component: File,
    isPrivate: true,
  },
  {
    path: "/connected-calendar",
    exact: true,
    component: ConnectedCalendar,
    isPrivate: false,
  },
];

const RouteWithSubRoutes = (route) => (
  <Route
    path={route.path}
    render={(props) => (
      <route.component {...props} test={route} routes={route.routes} />
    )}
  />
);

// eslint-disable-next-line react/prop-types
const ProtectedRoute = ({ component, ...args }) => {
  window.localStorage.setItem("routeLocation", window.location.pathname);
  return (
    <AppLogout {...args}>
      <DashboardProvider>
        <FiltersProvider>
          <Route
            component={withAuthenticationRequired(component, {
              onRedirecting: () => <Loading text="Please Wait" />,
              returnTo: () => {
                return window.location.pathname;
              },
            })}
            {...args}
          />
        </FiltersProvider>
      </DashboardProvider>
    </AppLogout>
  );
};

const Routes = () => (
  <BrowserRouter>
    <Switch>
      {routes.map((route, i) =>
        route.isPrivate ? (
          <ProtectedRoute key={i} {...route} />
        ) : (
          <RouteWithSubRoutes key={i} {...route} />
        ) //  <PrivateRoute key={i} {...route} />
      )}
      <Route
        path="/callback"
        render={(props) => {
          return <Callback {...props} />;
        }}
      />
      <Route component={NotFound} />
    </Switch>
  </BrowserRouter>
);

export default Routes;
