import { useEffect, useContext } from "react";
import { ProjectContext } from "context";
import { withAuth0 } from "@auth0/auth0-react";

const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN;
const AUTH0_RETURN = process.env.REACT_APP_AUTH0_RETURN;
const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_KEY;

const events = [
  "load",
  "mousemove",
  "mousedown",
  "click",
  "scroll",
  "keypress",
];

const AppLogout = ({ children, auth0 }) => {
  const { isAuthenticated, isLoading } = auth0;
  const { contextConfirm, contextAlert } = useContext(ProjectContext);
  const [, setConfirm] = contextConfirm; // eslint-disable-line
  const [, setAlert] = contextAlert; // eslint-disable-line
  let timer;
  let timerAlert;

  const handleLogoutTimerAlert = () => {
    timerAlert = setTimeout(() => {
      resetTimerAlert();
      Object.values(events).forEach((item) => {
        window.removeEventListener(item, resetTimerAlert);
      });
      logoutAlertAction();
    }, 25 * 60 * 1000);
  };

  const handleLogoutTimer = () => {
    timer = setTimeout(() => {
      resetTimer();
      Object.values(events).forEach((item) => {
        window.removeEventListener(item, resetTimer);
      });
      logoutAction();
    }, 30 * 60 * 1000);
  };

  const resetTimer = () => {
    if (timer) clearTimeout(timer);
  };

  const resetTimerAlert = () => {
    if (timerAlert) clearTimeout(timerAlert);
  };

  useEffect(() => {
    Object.values(events).forEach((item) => {
      if (typeof window !== undefined) {
        window.addEventListener(item, () => {
          resetTimer();
          resetTimerAlert();

          if (!isLoading && isAuthenticated) {
            handleLogoutTimerAlert();
            handleLogoutTimer();
          }
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isAuthenticated]);

  const _confirmAction = () => {
    localStorage.clear();
    if (typeof window !== undefined) {
      localStorage.removeItem("token");
      localStorage.removeItem("authUserId");
      window.location = `https://${AUTH0_DOMAIN}/v2/logout?returnTo=${AUTH0_RETURN}&client_id=${AUTH0_CLIENT_ID}`;
    }
  };

  const logoutAlertAction = () => {
    setAlert({
      display: true,
      title: "Inactivity Alert",
      text: "You will be logged out in 5 minutes due to inactivity.",
    });
  };

  const logoutAction = () => {
    setAlert({ display: false, title: null, text: null })
    setConfirm({
      display: true,
      title: "Logged out due to inactivity.",
      text: "You have been logged out due to inactivity. Please log in again to access Zaya.",
      meta: {
        type: "INACTIVITY_LOGOUT",
        confirmText: "OK",
        hideCancel: true,
      },
      callback: _confirmAction,
    });
  };

  return children;
};

export default withAuth0(AppLogout);
