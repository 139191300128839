import React, { createContext, useContext, useState } from "react";

const FiltersContext = createContext();

export const FiltersProvider = ({ children }) => {
  const [eligibilityFilters, setEligibilityFilters] = useState("")
  const [priorAuthFilters, setPriorAuthFilters] = useState("")
  const [claimsFilters, setClaimsFilters] = useState("")


  const value = {
    eligibilityFilters,
    setEligibilityFilters,
    priorAuthFilters,
    setPriorAuthFilters,
    claimsFilters,
    setClaimsFilters,
  };

  return (
    <FiltersContext.Provider value={value}>
      {children}
    </FiltersContext.Provider>
  );
};

export const useFiltersState = () => useContext(FiltersContext);
