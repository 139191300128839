import React, { useState, createContext } from 'react'

export const ProjectContext = createContext()

export const ProjectProvider = (props) => {
  const contextError = useState(null);
  const contextConfirm = useState({ type: null, name: null, description: null, meta: null, callback: null });
  const contextAlert = useState({ display: false, title: null, text: null });

  const params = { contextAlert, contextConfirm, contextError }

  return (
    <ProjectContext.Provider value = {params}>
      {props.children}
    </ProjectContext.Provider>
  )
}
