import React, { Fragment } from 'react'
import { Router } from 'react-router'
import { createBrowserHistory } from 'history'
import Routes from 'routes'
import { Auth0Provider } from "@auth0/auth0-react"
import { CookiesProvider } from 'react-cookie'
import { ProjectProvider } from 'context'
import { MixpanelProvider } from 'react-mixpanel'

import '../../styles/App.scss'
import { initializeMixpanel } from 'lib/mixpanel'

class Core extends React.Component {
  constructor (props) {
    super(props)

    this._history = createBrowserHistory()
  }

  render () {
    const mixpanel = initializeMixpanel();

    return (
      <ProjectProvider>
        <MixpanelProvider mixpanel={mixpanel}>
          <Auth0Provider
            domain={process.env.REACT_APP_AUTH0_DOMAIN}
            clientId={process.env.REACT_APP_AUTH0_KEY}
            redirectUri={process.env.REACT_APP_AUTH0_CALLBACK}
            useCookiesForTransactions={true}
          >
            <CookiesProvider>
              <Router history={this._history}>
                <Fragment>
                  {this.props.children}
                  <Routes />
                </Fragment>
              </Router>
            </CookiesProvider>
          </Auth0Provider>
        </MixpanelProvider>
      </ProjectProvider>
    )
  }
}

export default Core
