// src/Callback/Callback.js

import React, { Component } from 'react'
import { withAuth0 } from '@auth0/auth0-react'

import Loading from 'components/Loading'
const Callback = withAuth0(class Callback extends Component {
  componentDidMount() {
    localStorage.removeItem('authUserId')
    localStorage.removeItem('token')
  }
  componentDidUpdate() {
    this._checkLogin()
  }
  async _checkLogin() {
    const { auth0, auth0: { isAuthenticated, isLoading }, history } = this.props

    if (!isLoading && isAuthenticated) {

      const tokenPayload = await auth0.getIdTokenClaims()
      if (tokenPayload && tokenPayload.__raw) {
        localStorage.setItem('authUserId', tokenPayload.sub)
        localStorage.setItem('token', tokenPayload.__raw)
        history.push('/dashboard')
      }
    }
  }
  render() {
    const { auth0: { isAuthenticated, isLoading, error } } = this.props

    if (isAuthenticated || isLoading) {
      let message = (isAuthenticated) ? 'Success! Redirecting...' : 'Loading'
      return <Loading text={message} />
    }

    if (error) {
      throw new Error(error)
    }

    return <p>Not sure</p>
  }
})

export default Callback