import React from 'react'
// import styles from './styles.module.scss'

const Loading = props => {

  let classes = 'loading'

  if (props.noOpacity) {
    classes = `loading loadingNoOpacity`
  }
  if (props.className) {
    classes = `${classes} ${props.className}`
  }

  return (
    <div className={classes}>Loading&#8230;</div>
  );
}

export default Loading